@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

// StarRating component styles
.scoreStars {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .starIcon {
    width: 24px;
    height: 24px;

    @include max-screen(992px) {
      width: 22px;
      height: 22px;
    }
  }
}
