@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.linkedCategories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 55px;

  @include max-screen(992px) {
    margin-bottom: 40px;
  }

  @include max-screen(767px) {
    margin-bottom: 30px;
  }
}

.linkedCategoryItem {
  flex: 1;
  height: 447px;
  overflow: hidden;
  margin-bottom: 2%;
  margin-right: 30px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  img {
    height: 100%;
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  &:hover .overlay {
    opacity: 0.5;
  }

  @include max-screen(992px) {
    height: 296px;
    margin-bottom: 4%;
  }

  @include max-screen(767px) {
    flex: initial;
    width: 100%;
    margin: 0 0 10px;
    height: 266px;
  }
}

.link {
  text-align: center;

  @include max-screen(767px) {
    bottom: 0;
    left: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
  }
}

.overlay {
  background-image: linear-gradient(
    to top,
    rgba(70, 54, 39, 0.3),
    rgba(0, 0, 0, 0)
  );
  bottom: 0;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms linear;
}

.text {
  bottom: 27px;
  position: absolute;
  text-align: center;
  width: 100%;

  span {
    @include button-cta-font;
    color: $white;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}

.title {
  @include h3-font-demi;
  text-align: center;
  padding: 0 5px;
  margin: 50px 0 33px;

  @include max-screen(992px) {
    margin: 45px 0 23px;
  }
}

.twoColumnsLayout {
  &.linkedCategories {
    justify-content: space-between;
  }

  .linkedCategoryItem {
    flex: none;
    width: 49%;
    margin-right: 0;

    @include max-screen(992px) {
      height: 256px;
      width: 48%;
    }

    @include max-screen(767px) {
      height: 266px;
      width: 100%;
    }
  }
}
