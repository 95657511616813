@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  background: $sand;
  color: $charcoal;
  padding: 6px 0;
  display: flex;
  justify-content: center;
}

.field {
  &:not(:first-child) {
    border-left: 1px solid currentColor;
  }

  font-family: Arial;
  line-height: normal;

  display: flex;
  padding: 0 8px;
  flex-direction: column;
  text-align: center;
}

.fieldValue {
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0.3px;
}

// Countdown Ribbon styles
.countdownRibbon {
  &.container {
    background: none;
    color: $sand;
    padding: 10px 0;
  }

  .fieldValue {
    font-size: rem(16);
    letter-spacing: rem(2);
  }

  .fieldUnit {
    letter-spacing: rem(1);
    font-size: rem(9);
    line-height: rem(15);
  }
}
