@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  width: 100%;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 16;

  @include max-screen(767px) {
    .header {
      border-bottom: solid 1px $neutral;
    }
  }
}

.headline {
  width: 100%;
  max-width: 1220px;
  height: 52px;
  display: flex;
  margin: auto;
  justify-content: space-around;
}

.header,
.countdown {
  @include button-cta-font;
  align-self: center;
  display: inline-block;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
}

.header {
  margin-right: 15px;
}

.countdown {
  display: flex;
  justify-content: center;
  margin-left: 15px;

  svg {
    vertical-align: text-top;
    width: 21px;
    position: relative;
    left: 6px;
    top: 6px;
    * {
      stroke-width: 4;
    }
  }

  &.hideCountdown {
    display: none;
  }
}

.content {
  max-width: 1220px;
  margin: auto;
  font-size: rem(12);
  line-height: rem(20);
  letter-spacing: rem(0.25);
  padding: 15px 0;

  a {
    border-bottom: 1px solid;
  }
}

@include max-screen(1280px) {
  .headline {
    margin: 0;
  }
  .header {
    margin: 0 15px 0 30px;
  }
  .countdown {
    margin: 0 30px 0 15px;
  }

  .content {
    margin: 0 30px;
  }
}

// Tablet

@include max-screen(1024px) {
  .header,
  .countdown {
    width: 100%;
  }
  .content {
    padding: 15px 0;
  }
}

// Mobile
@include max-screen(767px) {
  .headline {
    display: block;
    height: auto;
  }

  .header,
  .countdown,
  .content {
    width: 100%;
    margin: 0;
  }

  .header {
    line-height: rem(25);
    padding: 13px 10px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .countdown {
    justify-content: center;
  }

  .content {
    padding: 13px 10px;
    line-height: rem(20);
    font-size: rem(10);
  }
}

// Background colour styles need to be consistent with Mobile Banner
.redBackground {
  background-color: $dark-red;
}

.lightRedBackground {
  background-color: $light-red;
}

.greenBackground {
  background-color: $petrol-green;
}

.greyBackground {
  background-color: $charcoal;
}

.yellowBackground {
  background-color: $light-peach;
}

.warmYellowBackground {
  background-color: $warm-yellow;
}

.blueBackground {
  background-color: $dark-blue;
}

.neonPurpleBackground {
  background-color: $neon-purple;
}

.deepPinkBackground {
  background-color: $deep-pink;
}

.redBackground,
.greenBackground,
.greyBackground,
.blueBackground {
  color: $sand;

  svg {
    * {
      stroke: $sand;
    }
  }
}

.yellowBackground {
  color: $charcoal;

  svg {
    * {
      stroke: $charcoal;
    }
  }

  :global {
    .CountdownDisplay__countdownRibbon {
      color: $charcoal;
    }
  }
}

.warmYellowBackground {
  color: $black;

  svg {
    * {
      stroke: $black;
    }
  }

  :global {
    .CountdownDisplay__countdownRibbon {
      color: $black;
    }
  }
}

.lightRedBackground,
.neonPurpleBackground,
.deepPinkBackground {
  color: $white;

  svg {
    * {
      stroke: $white;
    }
  }

  :global {
    .CountdownDisplay__countdownRibbon {
      color: $white;
    }
  }
}

:global(.dark-mode) {
  .container,
  .redBackground,
  .yellowBackground,
  .greyBackground,
  .blueBackground {
    @extend .greenBackground;
  }
}
