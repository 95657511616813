@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

// Customer review component styles
.review {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $ice;
  padding: 30px 0 50px;

  @include max-screen(1024px) {
    padding: 30px 0;
  }

  @include max-screen(767px) {
    flex-direction: column;
    padding: 20px 0 30px;
  }
}

.reviewInfo {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include max-screen(767px) {
    width: 100%;
  }

  .customerName {
    @include body-font-3;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .verifiedBadge {
    @include body-font-6;
    color: $neutral;
    margin-left: 10px;

    .verifiedBadgeIcon {
      margin-right: 5px;
    }
  }

  .starRating {
    margin: 13px 0 20px;

    @include max-screen(767px) {
      margin: 10px 0 20px;
    }
  }

  .date {
    @include body-font-4;
  }
}

.reviewText {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include max-screen(767px) {
    width: 100%;
  }

  .title {
    @include subheader-font-demi;
    margin-bottom: 10px;

    @include max-screen(767px) {
      margin-top: 20px;
    }
  }

  .text {
    @include body-font-2;
    margin-bottom: 0;
  }

  .reviewReplyContainer {
    margin-top: 10px;

    @include max-screen(767px) {
      margin-top: 25px;
    }
  }
}
