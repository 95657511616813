@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../../scss/functions';
@import '../../../../../../scss/config';
@import '../../../../../../scss/mixins';
@import '../../../../../../scss/vars';
@import '../../../../../../scss/media_queries';

.replyContainer {
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  border-radius: 4px;
  background-color: $paper;
  position: relative;

  .replyIcon {
    position: absolute;
    top: 19px;
    left: 7px;
    height: 11px;
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .replyTitle {
      @include body-font-5;
      line-height: rem(28);
      letter-spacing: rem(0.17);
    }

    .replyDate {
      @include body-font-4;
    }
  }

  .replyText {
    @include body-font-4;
    margin-top: 5px;
    line-height: rem(25);
    letter-spacing: rem(0.21);
  }
}
