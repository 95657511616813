@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.list {
  padding: 0;
  margin: 0;
}

.item {
  display: inline-block;
  line-height: 10px;
  padding: 10px 0;

  a,
  a span {
    @include body-font-5;
    color: $charcoal;
    cursor: default;
  }

  a[href],
  a[href] span {
    @include body-font-4;
    color: $charcoal;
    cursor: pointer;
  }

  a[href]:hover {
    border-bottom: 1px solid $charcoal;
  }
}

.iconWrap {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  width: 26px;
}

.chevron {
  max-height: 32px; // IE fix
  vertical-align: middle; // IE fix
  width: 10px;

  path {
    stroke: $ash;
    stroke-width: 9px;
  }
}

.staticLayout {
  margin-bottom: 10px;

  .item {
    a,
    a > span {
      font-size: rem(14);
    }
  }
}

:global(.dark-mode) .container {
  background: $charcoal;

  @include min-screen(768px) {
    border-top: 1px solid $ash;
  }
  * {
    background: $charcoal;
    color: $white !important;
  }
}

.hideOnMobile {
  @include max-screen(767px) {
    display: none;
  }
}
