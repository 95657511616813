@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.newsletter {
  background: $light-peach;
  margin-bottom: 30px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 150px;
  position: relative;
}

.formContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.input {
  flex: 1;
  margin-right: 30px;
  max-width: 666px;
  position: relative;

  input {
    @include body-font-4;
    padding: 0 20px;
    margin: 23px 0;

    ::placeholder {
      color: $neutral;
    }
  }
}

.button {
  min-width: 174px;
}

.text {
  @include body-font-3;
  color: $charcoal;
  margin-right: 26px;
  padding: 28px 0;
  text-align: center;
}

@include max-screen(1024px) {
  .container {
    margin: 0 20px;
  }

  .text {
    margin-right: 24px;
  }

  .input {
    input {
      padding: 0 14px;
    }
  }
}

@include max-screen(767px) {
  .text,
  .form,
  .input,
  .button {
    width: 100%;
  }

  .text,
  .button {
    padding: 0;
    margin: 0;
  }

  .form {
    display: block;
  }

  .input {
    position: static;
    margin-right: 0;
    max-width: 100%;

    input {
      margin: 15px 0 10px !important;
    }

    :global(.Input__errorMessage) {
      top: -3px !important;
    }
  }

  .container {
    flex-direction: column;
    max-width: 727px;
    padding: 23px 0 30px;
    position: relative;
    margin: 0 20px;
  }
}

:global(.dark-mode) .newsletter {
  background: $petrol-green;

  * {
    color: $white !important;
  }

  input {
    border-color: $white !important;
  }
  ::placeholder {
    color: $white !important;
  }
}
