@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  margin-top: 30px;
  margin-bottom: 20px;
  @include min-screen(768px) {
    background: $white;
  }

  &.whiteLayout {
    background: $white;

    .row {
      background: $white;
      padding: 30px 0 0;

      @include max-screen(992px) {
        margin: 0;
      }

      @include max-screen(767px) {
        padding: 25px 0 0;
      }
    }

    .logos {
      border-color: $ice;
      max-width: none;
      margin: 0;

      @include max-screen(992px) {
        padding: 20px 0;
      }

      @include max-screen(767px) {
        padding: 0;
      }
    }

    .widgetWrapper {
      border-color: $ice;
      padding-top: 0;
      width: 34%;

      @include max-screen(992px) {
        width: 49%;
      }

      @include max-screen(767px) {
        width: 100%;
        padding: 4px 0 25px;
      }
    }

    :global(.PressLogos__container) {
      background: $white;

      @include max-screen(992px) {
        align-items: normal;
        justify-content: space-between;
        height: 80px;
      }

      @include max-screen(767px) {
        align-items: center;
        height: auto;
      }
    }

    :global(.PressLogos__text) {
      @include body-font-3;
      text-transform: none;
      color: $charcoal;

      @include max-screen(992px) {
        @include body-font-5;
        width: 100%;
        padding: 0 20px;
      }

      @include max-screen(767px) {
        @include body-font-3;
        width: auto;
        margin: 5px 0 10px;
      }
    }

    :global(.SellingPoints__wrapper) {
      background: $white;

      padding: 0 30px;
      width: 62%;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 66%;

      @include max-screen(992px) {
        width: 51%;
        padding: 10px 0 10px 10px;
      }

      @include max-screen(767px) {
        width: 100%;
        padding: 0;
      }

      & > div {
        padding: 0;
      }

      @include max-screen(1024px) {
        padding: 0 20px;
      }

      @include max-screen(992px) {
        margin: 0;
        padding: 0 10px;
        width: 55%;
      }

      @include max-screen(767px) {
        padding: 0;
        width: 100%;
      }
    }

    :global(.SellingPoints__row) {
      @include max-screen(992px) {
        padding: 0 10px;
      }

      @include max-screen(767px) {
        padding: 0;
      }
    }

    :global(.SellingPoints__icon) {
      @include max-screen(992px) {
        margin-right: 6px;
      }

      @include max-screen(767px) {
        margin-right: 28px;
      }
    }

    :global(.SellingPoints__point) {
      padding: 10px 20px 10px 0;
      padding: 15px 20px 15px 0;
      width: 33.333%;

      h5 {
        @include body-font-3;
        white-space: normal;
      }

      svg {
        width: 35px;
        height: 35px;
      }

      @include max-screen(1024px) {
        padding: 15px 0;
      }

      @include max-screen(992px) {
        padding: 5px;
        width: 50%;

        h5 {
          @include body-font-5;
        }

        &:nth-child(3) {
          display: none;
        }
      }

      @include max-screen(767px) {
        padding: 15px 55px;
        width: 100%;
        border-color: $ice;
        padding: 18px 40px;

        h5 {
          @include body-font-3;
        }

        svg {
          width: 40px;
          height: 40px;
        }

        &:nth-child(2) {
          border: none;
        }
      }
      @include max-screen(767px) {
        padding: 18px 90px;
      }
    }
  }
}

.wrapper {
  display: block;
}

.row {
  align-items: center;
  background: $paper;
  justify-content: space-around;
  display: flex;
  padding: 40px 0;

  @include max-screen(767px) {
    display: block;
    padding: 25px 0 0;
    margin: 0;
  }
}

.widgetWrapper {
  width: 38%;
  border-right: 1px solid $ice;
  padding-top: 10px;
  padding-bottom: 10px;

  @include max-screen(992px) {
    padding: 0 15px;
    width: 45%;
  }

  @include max-screen(767px) {
    border: none;
    border-bottom: 1px solid $ice;
    padding-bottom: 20px;
    width: 100%;
  }
}

.logos {
  border-top: 1px solid $ice;
  @include max-screen(767px) {
    margin: 0;
  }
}

:global(.dark-mode) .container {
  background: $charcoal;

  .wrapper {
    * {
      background: $charcoal !important;
      color: $white !important;
    }
  }

  :global(.ShowroomUsps__usp) {
    svg {
      * {
        stroke: $white !important;
      }
    }
  }
}
