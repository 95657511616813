@charset "utf-8";

//
// Variables and mixins that are shared between the global CSS and CSS modules
//
@import '../../../../../scss/functions';
@import '../../../../../scss/config';
@import '../../../../../scss/mixins';
@import '../../../../../scss/vars';
@import '../../../../../scss/media_queries';

.container {
  color: $text-color-primary;
  margin-top: 40px;

  @include max-screen(767px) {
    margin: 30px 15px 0;
  }
}

.header {
  padding-bottom: 20px;
}

.title {
  @include h3-font-demi;
  text-align: center;
  margin-bottom: 20px;
}

.scoreContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  @include max-screen(767px) {
    flex-direction: column;
    align-items: unset;
  }
}

.score {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row;
  align-items: baseline;

  .starsContainer {
    margin: 0 15px 0 10px;
  }

  @include max-screen(767px) {
    justify-content: center;
  }
}

.scoreNumber {
  @include h1-font-demi;
  text-align: center;
}

.reviews {
  @include body-font-4;
  text-decoration: underline;
  text-underline-offset: 4px;
  align-self: center;
  display: none;
}

.logoContainer {
  display: flex;
  width: 0;

  @include max-screen(767px) {
    width: 100%;
    justify-content: center;
  }

  .trustpilotLogo {
    width: 105px;
    margin-left: -105px;

    @include max-screen(767px) {
      margin: 20px auto 0;
      display: flex;
    }
  }
}

.trustpilotContainer {
  @include max-screen(767px) {
    width: auto;
  }
}
